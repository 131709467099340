export default [
  {
    header: 'All-State',
    title: 'All-State',
    icon: 'MusicIcon',
    action: 'read',
    subject: 'all-state',
    children: [
      {
        title: 'Applications',
        route: 'all-state-applications',
        icon: 'ClipboardIcon',
        fa: ['far', 'clipboard'],
        action: 'read',
        subject: 'all-state-applications',
      },
      {
        title: 'Students',
        route: 'all-state-students',
        icon: 'UserIcon',
        fa: ['fas', 'graduation-cap'],
        action: 'read',
        subject: 'all-state-students',
      },
      {
        title: 'Scores',
        route: 'all-state-scores',
        icon: 'HashIcon',
        //fa: ['fas', 'sort-numeric-down'],
        action: 'read',
        subject: 'all-state-scores',
      },
      {
        title: 'Selections',
        route: 'all-state-selections',
        icon: 'PlusSquareIcon',
        action: 'read',
        subject: 'all-state-selections',
      },
      {
        title: 'Acceptance',
        route: 'all-state-acceptance',
        icon: 'CheckSquareIcon',
        action: 'read',
        subject: 'all-state-acceptance',
      },
    ]
  },

  /** Events **/
  {
    header: 'Events',
    title: 'Events',
    icon: 'CalendarIcon',
    action: 'read',
    subject: 'events',
    children: [
      {
        title: 'Buses',
        icon: 'CalendarIcon',
        fa: ['fas', 'bus'],
        route: 'events-buses',
        action: 'read',
        subject: 'events-buses',
      },
      /*{
        title: 'Hotels',
        icon: 'CalendarIcon',
        fa: ['fas', 'hotel'],
        route: 'events-hotels',
        action: 'read',
        subject: 'events-hotels',
      },
      {
        title: 'Chaperones',
        icon: 'CalendarIcon',
        fa: ['fas', 'people-roof'],
        route: 'events-chaperones',
        action: 'read',
        subject: 'events-chaperones',
      },
      {
        title: 'Students',
        icon: 'CalendarIcon',
        fa: ['fas', 'graduation-cap'],
        route: 'events-students',
        action: 'read',
        subject: 'events-students',
      },
      {
        title: 'Housing',
        icon: 'CalendarIcon',
        fa: ['fas', 'house'],
        route: 'events-housing',
        action: 'read',
        subject: 'events-housing',
      },*/
    ]
  },


  /* Reports */
  {
    header: 'Reports',
    title: 'Reports',
    action: 'read',
    subject: 'reports',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Recommendation',
        icon: 'InfoIcon',
        route: 'reports-application-recommendation',
        action: 'read',
        subject: 'reports-application-recommendation',
      },
      {
        title: 'State Ranking',
        icon: 'InfoIcon',
        route: 'reports-state-ranking',
        action: 'read',
        subject: 'reports-state-ranking',
      },
      {
        title: 'Part Assignment',
        icon: 'InfoIcon',
        route: 'reports-part-assignment',
        action: 'read',
        subject: 'reports-part-assignment',
      },
      {
        title: 'Ensemble Roster',
        icon: 'InfoIcon',
        route: 'reports-ensemble-roster',
        action: 'read',
        subject: 'reports-ensemble-roster',
      },
      {
        title: 'Chaperone Roster',
        icon: 'InfoIcon',
        route: 'reports-chaperone-roster',
        action: 'read',
        subject: 'reports-chaperone-roster',
      },
      {
        title: 'Festival Applications',
        icon: 'InfoIcon',
        route: 'reports-festival-applications',
        action: 'read',
        subject: 'reports-festival-applications',
      },
      {
        title: 'Multiple Selections',
        icon: 'InfoIcon',
        route: 'reports-multiple-selection-acceptance',
        action: 'read',
        subject: 'reports-multiple-selection-acceptance',
      },
    ]
  },


  {
    header: 'Printable',
    title: 'Printable',
    icon: 'PrinterIcon',
    children: [
      {
        title: 'Certificates',
        fa: ['fas', 'certificate'],
        route: 'printable-certificates',
        action: 'read',
        subject: 'printable-certificates',
      },
      {
        title: 'Letters',
        fa: ['far', 'envelope'],
        route: 'printable-letters',
        action: 'read',
        subject: 'printable-letters',
        children: [
          {
            title: 'Teacher Letters',
            fa: ['fas', 'envelope-open-text'],
            route: 'printable-letters-teacher',
            action: 'read',
            subject: 'printable-letters',
          },
          {
            title: 'School Letters',
            fa: ['fas', 'envelope-open-text'],
            route: 'printable-letters-school',
            action: 'read',
            subject: 'printable-letters',
          },
        ],
      },
      /*{
        title: 'Mailing Labels',
        fa: ['far', 'address-card'],
        route: 'printable-labels',
        action: 'read',
        subject: 'printable-labels',
      },*/
      {
        title: 'Name Badges',
        icon: 'PrinterIcon',
        fa: ['far', 'id-badge'],
        children: [
          {
            title: 'Conference',
            fa: ['far', 'id-card'],
            route: { to: 'events-badge', params: { id: 'conference' }},
            action: 'read',
            subject: 'events-badges',
          },
          {
            title: 'Albany',
            fa: ['far', 'id-card'],
            route: { to: 'events-badge', params: { id: 'albany' }},
            action: 'read',
            subject: 'events-badges',
          },
        ],
      },
      {
        title: 'Program Data',
        fa: ['far', 'newspaper'],
        route: 'printable-magazine-and-program',
        action: 'read',
        subject: 'printable-magazine-and-program',
      },
    ],
  },


  /* Management */
  {
    header: 'Management',
    title: 'Management',
    icon: 'SlidersIcon',
    action: 'read',
    subject: 'management',
    children: [
      {
        title: 'Billing',
        route: 'management-billing',
        // icon: 'CreditCardIcon',
        fa: ['fas', 'money-check'],
        children: [
          {
            title: 'Invoices',
            fa: ['fas', 'file-invoice-dollar'],
            route: 'management-invoices',
            action: 'read',
            subject: 'management-invoices',
          },
          /*{
            title: 'Purchase Orders',
            fa: ['fas', 'file-invoice'],
            route: 'management-purchase-orders',
            action: 'read',
            subject: 'management-purchase-orders',
          }*/
        ],
      },
      {
        title: 'Zones',
        icon: 'MapIcon',
        fa: ['far', 'map'],
        route: 'management-zones',
        action: 'read',
        subject: 'management-zones',
      },
      {
        title: 'Festivals',
        icon: 'HeadphonesIcon',
        route: 'management-festivals',
        action: 'read',
        subject: 'management-festivals',
      },
      {
        title: 'Forms',
        icon: 'LayoutIcon',
        route: 'management-forms',
        action: 'read',
        subject: 'management-forms',
      },
      {
        title: 'Instruments',
        icon: 'MusicIcon',
        route: 'management-instruments',
        action: 'read',
        subject: 'management-instruments',
      },
      {
        title: 'Ensembles',
        icon: 'SlidersIcon',
        fa: ['fas', 'people-line'],
        route: 'management-ensembles',
        action: 'read',
        subject: 'management-ensembles',
      },
      {
        title: 'Districts',
        route: 'management-districts',
        icon: 'UmbrellaIcon',
        fa: ['fas', 'building-columns'],
        action: 'read',
        subject: 'management-districts',
      },
      {
        title: 'Schools',
        icon: 'DropletIcon',
        fa: ['fas', 'school-flag'],
        route: 'management-schools',
        action: 'read',
        subject: 'management-schools',

      },
      {
        title: 'Users',
        icon: 'UsersIcon',
        //fa: ['fas', 'user-group'],
        route: 'management-users',
        action: 'read',
        subject: 'management-users',

      },

      {
        title: 'Settings',
        icon: 'SettingsIcon',
        action: 'read',
        subject: 'management-settings',
        children: [
          {
            title: 'Applications',
            route: 'management-settings-application',
            action: 'read',
            subject: 'management-settings-application',
          },
          {
            title: 'Selections',
            route: 'management-settings-selection',
            action: 'read',
            subject: 'management-settings-selection',
          },
          {
            title: 'Billing',
            route: 'management-settings-billing',
            action: 'read',
            subject: 'management-settings-billing',
          },
          {
            title: 'Support',
            route: 'management-settings-support',
            action: 'read',
            subject: 'management-settings-support',
          },
        ],
      },
    ]
  },

  /*{
    header: 'Events',
    title: 'Events',
    icon: 'CalendarIcon',
    action: 'read',
    subject: 'management-events',
    children: [
      {
        title: 'Buses',
        route: 'events-buses',
        action: 'read',
        subject: 'events-buses',
      },
      {
        title: 'Hotels',
        route: 'events-hotels',
        action: 'read',
        subject: 'events-hotels',
      },
      {
        title: 'Chaperones',
        route: 'events-chaperones',
        action: 'read',
        subject: 'events-chaperones',
      },
      {
        title: 'Students',
        route: 'events-students',
        action: 'read',
        subject: 'events-students',
      },
      {
        title: 'Housing',
        route: 'events-housing',
        action: 'read',
        subject: 'events-housing',
      },
    ],
  },*/

  {
    header: 'Dev',
    title: 'Settings',
    icon: 'CodeIcon',
    action: 'read',
    subject: 'dev',
    children: [
      {
        title: 'Import',
        fa: ['fas', 'file-import'],
        route: 'dev-import',
        action: 'read',
        subject: 'dev'
      },
      {
        title: 'Export',
        fa: ['fas', 'file-export'],
        route: 'dev-export',
        action: 'read',
        subject: 'dev'
      },
    ],
  },
]
